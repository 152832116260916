::-webkit-scrollbar {
    width: 7px;
}
::-webkit-scrollbar-track {
    
    background: #454343;
}
::-webkit-scrollbar-thumb {
    background: #6b6969;
}
* {
    text-decoration: none;
}